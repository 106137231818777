<script setup>
import { useDisplay } from "vuetify"
import { computed } from "vue"

const props = defineProps({
  autoFullscreen: {
    type: Boolean,
    default: true,
  },
  modelValue: Boolean,
  maxWidth: {
    default: "1000px",
    type: String,
  },
  persistent: {
    default: false,
    type: Boolean,
  },
})
const emit = defineEmits(["update:modelValue"])

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit("update:modelValue", value)
  },
})

const { smAndDown } = useDisplay()
const fullscreen = computed(() => {
  if (props.autoFullscreen) {
    return smAndDown.value
  }
  return false
})

const maxWidthComputed = computed(() => {
  if (fullscreen.value) {
    return "100%"
  } else {
    return props.maxWidth
  }
})
</script>

<template>
  <v-dialog
    v-model="value"
    :fullscreen="fullscreen"
    :max-width="maxWidthComputed"
    :persistent="persistent"
    scrim="black"
  >
    <slot></slot>
  </v-dialog>
</template>
